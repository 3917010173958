import { defineStore } from "pinia";
import axios from "axios";
axios.defaults.withCredentials = true;
export const useAuth = defineStore("authenticate", {
    state: () => ({
        user: undefined
    }),
    actions: {
        async login(email, password, remember) {
            if (!email || !password)
                return { success: false, errors: ['Invalid data'] };
            try {
                // Attempt to log the employee in
                const response = await axios.post('/login', {
                    email,
                    password,
                    remember
                });
                await this.getUser();
                return { success: true, errors: [] };
            }
            catch (err) {
                const errData = err.response?.data;
                return { success: false, errors: errData?.errors };
            }
        },
        async register(firstName, lastName, email, telephone, password, password_confirmation, address) {
            if (!firstName || !lastName || !email || !telephone || !password || !address)
                return false;
            try {
                // Attempt to log the employee in
                const response = await axios.post('/register', {
                    name: firstName + " " + lastName,
                    email,
                    telephone,
                    password,
                    password_confirmation,
                    address
                });
                await this.getUser();
                return { success: true, errors: [] };
            }
            catch (err) {
                const errData = err.response.data;
                return { success: false, errors: errData.errors };
            }
        },
        async save(name, email, telephone, addressLine1, addressCity, addressCounty, addressPostcode, addressLine2) {
            if (!name || !email || !telephone || !addressLine1 || !addressCity || !addressCounty || !addressPostcode)
                return false;
            try {
                // Attempt to log the employee in
                const response = await axios.put('/api/update', {
                    // Basic user info
                    name,
                    email,
                    telephone,
                    // Address info
                    addressLine1,
                    addressLine2,
                    addressCity,
                    addressCounty,
                    addressPostcode
                });
                await this.getUser();
                return { success: true, errors: [] };
            }
            catch (err) {
                const errData = err.response.data;
                return { success: false, errors: errData.errors };
            }
        },
        async logout() {
            const response = await axios.post('/logout');
            this.user = undefined;
        },
        async getUser() {
            try {
                // Attempt to get user data
                const userResponse = await axios.get('/api/user');
                this.user = userResponse.data;
            }
            catch (err) {
                // Attempt failed, ensure the user state is set back to unauthed
                this.user = undefined;
            }
            // Return the state, just to be safe
            return this.user;
        },
        async forgotPassword(email) {
            const response = await axios.post('/password-forgot', {
                email
            });
            return { success: true, errors: [] };
        },
        async resetPassword(uid, password, password_confirmation) {
            const response = await axios.post('/api/reset-password/' + uid, {
                password,
                password_confirmation
            });
            return { success: true, errors: [] };
        },
        can(permission) {
            if (!this.user)
                return false;
            for (const perm of (this.user?.usergroup?.permissions ?? [])) {
                if (perm.name == permission)
                    return true;
            }
            return false;
        }
    }
});
