import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        // Auth routes
        {
            path: "/auth",
            name: "auth",
            component: () => import("@/views/auth/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "auth.login",
                    component: () => import("@/views/auth/LoginView.vue"),
                },
                {
                    path: "register",
                    name: "auth.register",
                    component: () => import("@/views/auth/RegisterView.vue"),
                },
                {
                    path: "forgot-password",
                    name: "auth.forgot-password",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "auth.forgot-password.forgot",
                            component: () => import("@/views/auth/ForgotPasswordView.vue"),
                        },
                        {
                            path: ":uid",
                            name: "auth.forgot-password.reset",
                            component: () => import("@/views/auth/ResetPasswordView.vue"),
                        }
                    ]
                },
            ],
        },
        // Public Route
        {
            path: "/bulletin-board",
            name: "bulletin-board",
            component: () => import("@/views/bulletinboard/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "bulletin-board.index",
                    component: () => import("@/views/bulletinboard/IndexView.vue"),
                },
                {
                    path: "class/:id",
                    name: "bulletin-board.class",
                    component: () => import("@/views/bulletinboard/ClassProfileView.vue"),
                },
                {
                    path: "oneoffclass/:id",
                    name: "bulletin-board.oneoffclass",
                    component: () => import("@/views/bulletinboard/OneOffClassProfileView.vue"),
                }
            ],
        },
        // Member routes
        {
            path: "/select",
            name: "customer.select",
            component: () => import("@/views/customer/SelectView.vue"),
        },
        {
            path: "/",
            name: "customer.index",
            component: () => import("@/views/customer/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "customer.dashboard",
                    component: () => import("@/views/customer/IndexView.vue"),
                },
                {
                    path: "invoice",
                    name: "customer.invoice",
                    component: () => import("@/views/customer/InvoicesView.vue"),
                },
                {
                    path: "guardians",
                    name: "customer.guardians",
                    component: () => import("@/views/customer/GuardiansView.vue"),
                },
                {
                    path: "classes",
                    name: "customer.classes",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "customer.classes.index",
                            component: () => import("@/views/customer/ClassesView.vue"),
                        },
                        {
                            path: ":id",
                            name: "customer.classes.period",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "customer.classes.period.index",
                                    component: () => import("@/views/customer/ClassesPeriodsView.vue"),
                                },
                                {
                                    path: "join",
                                    name: "customer.classes.period.join",
                                    component: () => import("@/views/customer/JoinClassView.vue"),
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "class-invites",
                    name: "customer.class-invites",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: ":id",
                            name: "customer.class-invites.profile",
                            children: [
                                {
                                    path: "",
                                    name: "customer.class-invites.profile.index",
                                    component: () => import("@/views/customer/ReviewClassInvite.vue"),
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "oneoffclasses",
                    name: "customer.oneoffclasses",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "customer.oneoffclasses.index",
                            component: () => import("@/views/customer/OneOffClassesView.vue"),
                        },
                        {
                            path: ":id",
                            name: "customer.oneoffclasses.profile",
                            children: [
                                {
                                    path: "",
                                    name: "customer.oneoffclasses.profile.index",
                                    component: () => import("@/views/customer/JoinOneOffClass.vue"),
                                },
                                {
                                    path: "amend",
                                    name: "customer.oneoffclasses.profile.amend",
                                    component: () => import("@/views/customer/AmendOneOffClass.vue"),
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "parties",
                    name: "customer.parties",
                    children: [
                        {
                            path: "",
                            name: "customer.parties.index",
                            component: () => import("@/views/customer/PartyView.vue"),
                        },
                        {
                            path: "book",
                            name: "customer.parties.book",
                            component: () => import("@/views/customer/PartyBookView.vue"),
                        },
                        {
                            path: "tos",
                            name: "customer.parties.tos",
                            component: () => import("@/views/customer/PartyToSView.vue"),
                        },
                    ],
                },
                {
                    path: "consent",
                    name: "customer.consent",
                    component: () => import("@/views/customer/ConsentView.vue"),
                },
                {
                    path: "notifications",
                    name: "customer.notifications",
                    component: () => import("@/views/customer/NotificationsView.vue"),
                },
                {
                    path: "account",
                    name: "customer.account",
                    component: () => import("@/views/customer/AccountView.vue"),
                },
                {
                    path: "member",
                    name: "customer.member",
                    component: () => import("@/views/customer/MemberView.vue"),
                },
                {
                    path: "membership",
                    name: "customer.membership",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "customer.membership.index",
                            component: () => import("@/views/customer/MembershipView.vue"),
                        },
                        {
                            path: ":id",
                            name: "customer.membership.create",
                            component: () => import("@/views/customer/MembershipCreateView.vue"),
                        },
                    ],
                },
            ],
        },
        // Staff routes
        {
            path: "/staff",
            name: "staff.index",
            component: () => import("@/views/staff/LayoutView.vue"),
            children: [
                {
                    path: "",
                    name: "staff.dashboard",
                    component: () => import("@/views/staff/IndexView.vue"),
                },
                {
                    path: "calendar",
                    name: "staff.calendar",
                    component: () => import("@/views/staff/calendar/IndexView.vue"),
                },
                {
                    path: "staff",
                    name: "staff.staff",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.staff.index",
                            component: () => import("@/views/staff/staff/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.staff.create",
                            component: () => import("@/views/staff/staff/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.staff.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.staff.profile.view",
                                    component: () => import("@/views/staff/staff/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.staff.profile.edit",
                                    component: () => import("@/views/staff/staff/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "users",
                    name: "staff.users",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.users.index",
                            component: () => import("@/views/staff/users/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.users.create",
                            component: () => import("@/views/staff/users/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.users.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.users.profile.view",
                                    component: () => import("@/views/staff/users/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.users.profile.edit",
                                    component: () => import("@/views/staff/users/CreateView.vue"),
                                },
                                {
                                    path: "guardian",
                                    name: "staff.users.profile.guardian",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.users.profile.guardian.create",
                                            component: () => import("@/views/staff/users/guardians/CreateView.vue"),
                                        },
                                    ],
                                },
                                {
                                    path: "members",
                                    name: "staff.users.profile.members",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.users.profile.members.create",
                                            component: () => import("@/views/staff/users/members/CreateView.vue"),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "members",
                    name: "staff.members",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.members.index",
                            component: () => import("@/views/staff/members/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.members.create",
                            component: () => import("@/views/staff/members/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.members.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.members.profile.view",
                                    component: () => import("@/views/staff/members/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.members.profile.edit",
                                    component: () => import("@/views/staff/members/CreateView.vue"),
                                },
                                {
                                    path: "badges",
                                    name: "staff.members.profile.badges",
                                    component: () => import("@/views/staff/members/badges/CreateView.vue"),
                                },
                                {
                                    path: "memberships",
                                    name: "staff.members.profile.memberships",
                                    component: () => import("@/views/staff/members/memberships/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "classes",
                    name: "staff.classes",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.classes.index",
                            component: () => import("@/views/staff/classes/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.classes.create",
                            component: () => import("@/views/staff/classes/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.classes.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.classes.profile.view",
                                    component: () => import("@/views/staff/classes/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.classes.profile.edit",
                                    component: () => import("@/views/staff/classes/CreateView.vue"),
                                },
                                {
                                    path: "periods",
                                    name: "staff.classes.profile.periods",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.classes.profile.periods.create",
                                            component: () => import("@/views/staff/classes/periods/CreateView.vue"),
                                        },
                                        {
                                            path: ":periodid",
                                            name: "staff.classes.profile.periods.profile",
                                            component: () => import("@/views/ShellView.vue"),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "staff.classes.profile.periods.profile.index",
                                                    component: () => import("@/views/staff/classes/periods/ProfileView.vue"),
                                                },
                                                {
                                                    path: "edit",
                                                    name: "staff.classes.profile.periods.profile.edit",
                                                    component: () => import("@/views/staff/classes/periods/CreateView.vue"),
                                                },
                                                {
                                                    path: "session",
                                                    name: "staff.classes.profile.periods.profile.session",
                                                    component: () => import("@/views/staff/classes/periods/SessionView.vue"),
                                                },
                                            ]
                                        }
                                    ],
                                },
                                {
                                    path: "sessions",
                                    name: "staff.classes.profile.sessions",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.classes.profile.sessions.create",
                                            component: () => import("@/views/staff/classes/sessions/CreateView.vue"),
                                        },
                                    ],
                                },
                                {
                                    path: "members",
                                    name: "staff.classes.profile.members",
                                    component: () => import("@/views/staff/classes/members/CreateView.vue"),
                                },
                                {
                                    path: "invites",
                                    name: "staff.classes.profile.invites",
                                    component: () => import("@/views/staff/classes/invites/CreateView.vue"),
                                },
                                {
                                    path: "waitlist",
                                    name: "staff.classes.profile.waitlist",
                                    component: () => import("@/views/staff/classes/waitlist/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "oneoffclasses",
                    name: "staff.oneoffclasses",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.oneoffclasses.index",
                            component: () => import("@/views/staff/oneoffclasses/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.oneoffclasses.create",
                            component: () => import("@/views/staff/oneoffclasses/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.oneoffclasses.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.oneoffclasses.profile.index",
                                    component: () => import("@/views/staff/oneoffclasses/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.oneoffclasses.profile.edit",
                                    component: () => import("@/views/staff/oneoffclasses/CreateView.vue"),
                                },
                                {
                                    path: "members",
                                    name: "staff.oneoffclasses.profile.members",
                                    component: () => import("@/views/staff/oneoffclasses/members/CreateView.vue"),
                                },
                                {
                                    path: "invites",
                                    name: "staff.oneoffclasses.profile.invites",
                                    component: () => import("@/views/staff/oneoffclasses/invites/CreateView.vue"),
                                },
                                {
                                    path: "register",
                                    name: "staff.oneoffclasses.profile.register",
                                    component: () => import("@/views/staff/oneoffclasses/members/RegisterView.vue"),
                                },
                                {
                                    path: "waitlist",
                                    name: "staff.oneoffclasses.profile.waitlist",
                                    component: () => import("@/views/staff/oneoffclasses/waitlist/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "consent",
                    name: "staff.consent",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.consent.index",
                            component: () => import("@/views/staff/consent/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.consent.create",
                            component: () => import("@/views/staff/consent/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.consent.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.consent.profile.index",
                                    component: () => import("@/views/staff/consent/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "badges",
                    name: "staff.badges",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.badges.index",
                            component: () => import("@/views/staff/badges/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.badges.create",
                            component: () => import("@/views/staff/badges/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.badges.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.badges.profile.edit",
                                    component: () => import("@/views/staff/badges/CreateView.vue"),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "invoices",
                    name: "staff.invoices",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.invoices.index",
                            component: () => import("@/views/staff/invoices/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.invoices.create",
                            component: () => import("@/views/staff/invoices/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.invoices.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.invoices.profile.view",
                                    component: () => import("@/views/staff/invoices/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.invoices.profile.edit",
                                    component: () => import("@/views/staff/invoices/CreateView.vue"),
                                },
                                {
                                    path: "items",
                                    name: "staff.invoices.profile.items",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.invoices.profile.items.view",
                                            component: () => import("@/views/staff/invoices/items/CreateView.vue"),
                                        },
                                    ],
                                },
                                {
                                    path: "payments",
                                    name: "staff.invoices.profile.payments",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.invoices.profile.payments.view",
                                            component: () => import("@/views/staff/invoices/payments/CreateView.vue"),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "parties",
                    name: "staff.parties",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.parties.index",
                            component: () => import("@/views/staff/parties/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.parties.create",
                            component: () => import("@/views/staff/parties/CreateView.vue"),
                        },
                        {
                            path: ":id",
                            name: "staff.parties.profile",
                            component: () => import("@/views/ShellView.vue"),
                            children: [
                                {
                                    path: "",
                                    name: "staff.parties.profile.view",
                                    component: () => import("@/views/staff/parties/ProfileView.vue"),
                                },
                                {
                                    path: "edit",
                                    name: "staff.parties.profile.edit",
                                    component: () => import("@/views/staff/parties/CreateView.vue"),
                                },
                                {
                                    path: "period",
                                    name: "staff.parties.profile.periods.index",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.parties.profile.periods.create",
                                            component: () => import("@/views/staff/parties/periods/CreateView.vue"),
                                        },
                                        {
                                            path: ":periodid/edit",
                                            name: "staff.parties.profile.periods.edit",
                                            component: () => import("@/views/staff/parties/periods/CreateView.vue"),
                                        },
                                    ]
                                },
                                {
                                    path: "booking",
                                    name: "staff.parties.profile.bookings",
                                    component: () => import("@/views/ShellView.vue"),
                                    children: [
                                        {
                                            path: "create",
                                            name: "staff.parties.profile.bookings.create",
                                            component: () => import("@/views/staff/parties/bookings/CreateView.vue"),
                                        },
                                        {
                                            path: ":bookingid",
                                            name: "staff.parties.profile.bookings.booking",
                                            component: () => import("@/views/ShellView.vue"),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "staff.parties.profile.bookings.booking.profile",
                                                    component: () => import("@/views/staff/parties/bookings/ProfileView.vue"),
                                                },
                                                {
                                                    path: "edit",
                                                    name: "staff.parties.profile.bookings.booking.edit",
                                                    component: () => import("@/views/staff/parties/bookings/CreateView.vue"),
                                                },
                                            ],
                                        },
                                    ]
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "notifications",
                    name: "staff.notifications",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.notifications.index",
                            component: () => import("@/views/staff/notifications/IndexView.vue"),
                        }
                    ],
                },
                {
                    path: "customer-notifications",
                    name: "staff.customer-notifications",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.customer-notifications.index",
                            component: () => import("@/views/staff/customer-notifications/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.customer-notifications.create",
                            component: () => import("@/views/staff/customer-notifications/CreateView.vue"),
                        },
                        {
                            path: "template/create",
                            name: "staff.customer-notifications.template.create",
                            component: () => import("@/views/staff/customer-notifications/CreateTemplateView.vue"),
                        },
                        {
                            path: "template/:id",
                            name: "staff.customer-notifications.template.update",
                            component: () => import("@/views/staff/customer-notifications/CreateTemplateView.vue"),
                        }
                    ],
                },
                {
                    path: "reports",
                    name: "staff.reports",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.reports.index",
                            component: () => import("@/views/staff/reports/IndexView.vue"),
                        },
                        {
                            path: "create",
                            name: "staff.reports.create",
                            component: () => import("@/views/staff/reports/CreateView.vue"),
                        }
                    ],
                },
                {
                    path: "coupons",
                    name: "staff.coupons",
                    component: () => import("@/views/ShellView.vue"),
                    children: [
                        {
                            path: "",
                            name: "staff.coupons.index",
                            component: () => import("@/views/staff/coupons/IndexView.vue"),
                        }
                    ],
                },
            ],
        },
    ],
});
export default router;
