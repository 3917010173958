import { ref } from "vue";
import { Mutex } from "async-mutex";
export function useFetch(callback) {
    const mutex = new Mutex();
    const loading = ref(false);
    const fetch = async (param1, param2) => {
        const release = await mutex.acquire();
        try {
            loading.value = true;
            const val = await callback(release, param1, param2);
            if (mutex.isLocked())
                release();
            loading.value = false;
            return val;
        }
        catch (err) {
            release();
            loading.value = false;
            throw err;
        }
    };
    return {
        loading,
        fetch,
    };
}
